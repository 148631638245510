import React from "react";
import styles from "./AdminConfig.module.css";
import NavCard from "../../components/NavCard";
import { support } from "../../../images";

const AdminConfig = () => {
  return (
    <>
      <div>
        <div className={styles.gridContainer}>
          <div>
            <NavCard
              title="Clients"
              actionText="Configure"
              icon={support}
              link="/admin/clients"
            />
          </div>
          <div>
            <NavCard
              title="Sprayers"
              actionText="Configure"
              icon={support}
              link="/admin/sprayers"
              // disabled
            />
          </div>
          <div>
            <NavCard
              title="Locations"
              actionText="Configure"
              icon={support}
              link="/admin/locations"
            />
          </div>
          <div></div>
          <div>
            <NavCard
              title="Diagnostics"
              actionText="Configure"
              icon={support}
              link="/admin/diagnostics"
              // disabled
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminConfig;
