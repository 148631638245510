import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import authService from "../../../api-authorization/AuthorizeService";

const UpdateClientDialog = ({ client, open, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm({
    defaultValues: client,
  });

  const updateClient = async (data) => {
    const token = await authService.getAccessToken();
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/clients/update`,
        {
          method: "PUT",
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        toast.success("Client updated successfully!");
        onSuccess();
      } else {
        toast.error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      toast.error("Error: " + err);
      console.log("err", err);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(updateClient)}>
        <DialogTitle>
          <EditIcon /> Edit Client
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl>
              <FormLabel>Client Name</FormLabel>
              <TextField {...register("name")} />
            </FormControl>
            <FormControl>
              <FormLabel>E-mail</FormLabel>
              <TextField {...register("primaryContactEmail")} />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <TextField {...register("primaryContactPhone")} />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disable={loading}>
            Cancel
          </Button>
          <Button type="submit" variant="outlined" disabled={loading}>
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateClientDialog;
